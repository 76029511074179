<template>
  <div class="view pa24">
    <el-date-picker
      v-model="pickerValue"
      class="mr10"
      align="right"
      type="daterange"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
      :picker-options="pickerOptions"
    />
    <el-input class="w220 mr10" placeholder="企业名称/管理员电话" />
    <el-button>查询</el-button>
    <el-button type="primary">导出</el-button>
    <commonTable :tableData="tableData">
      <template v-slot:table>
        <el-table-column prop="serialNumber" align="center" label="编号" />
        <el-table-column prop="companyName" align="center" label="企业名称" />
        <el-table-column
          prop="administrator"
          align="center"
          label="管理员"
          show-overflow-tooltip
        />
        <el-table-column
          prop="administratorPhone"
          align="center"
          label="管理员电话"
        />
        <el-table-column
          prop="commodityIncome"
          align="center"
          label="商品收入"
        />
        <el-table-column
          prop="commissionExpense"
          align="center"
          label="分销佣金支出"
        />
        <el-table-column
          prop="corporateServices"
          align="center"
          label="企业服务（支出）"
        />
        <el-table-column align="center" label="操作">
          <template>
            <el-button type="text">详情</el-button>
          </template>
        </el-table-column>
      </template>
    </commonTable>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
export default {
  name: "moneyManagement",
  components: {
    commonTable,
  },
  data() {
    return {
      pickerValue: "", //选择时间
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      tableData: [
        {
          serialNumber: "150",
          companyName: "成都壹立科技有限公司",
          administrator: "付政",
          administratorPhone: "18683128612",
          commodityIncome: "1",
          commissionExpense: "1",
          corporateServices: "1",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
</style>